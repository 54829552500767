import React, { useState } from 'react';
import Box from '../../components/Box/Box';
import Row from '../../components/utils/Row/Row';
import {
  TransferItemEntry,
  TransferItemStatus,
  TransferItemStatusEnum,
} from './types';
import Column from '../../components/utils/Column/Column';
import Modal from '../../components/Modal/Modal';
import NewTransferDetail from './NewTransferDetail';
import { useActions } from '../../redux/hooks';

interface TransferItemProps {
  transferItem: TransferItemEntry;
}

const TransferItem: React.FC<TransferItemProps> = ({
  transferItem: {
    id,
    merchant,
    durum,
    musteri,
    banka,
    verilen_banka,
    tam_adi,
    miktar,
    sure,
    backuser,
  },
}) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const { getNewTransferInfoThunk, setLoading } = useActions();

  const closeModal = () => {
    setIsDetailsOpen(false);
  };

  return (
    <Row width="100%">
      <Modal
        onChange={(isOpen) => {
          setIsDetailsOpen(isOpen);
        }}
        open={isDetailsOpen}
        width="80vw"
      >
        <NewTransferDetail id={id} closeModal={closeModal} />
      </Modal>
      <Box
        onClick={async () => {
          setLoading(true);
          await getNewTransferInfoThunk({ id });
          setLoading(false);
          setIsDetailsOpen(true);
        }}
      >
        <Row
          width="100%"
          className={`transfer-item-row ${TransferItemStatusEnum[durum]}`}
        >
          <Column width="33%" className={`transfer-item`}>
            <Row className="transfer-item-field">
              <div>
                Merchant: <strong>{merchant}</strong>
              </div>
            </Row>
            <Row className="transfer-item-field">
              <div>
                Müşteri: <strong>{musteri}</strong>
              </div>
            </Row>
            <Row className="transfer-item-field">
              <div>
                Tam adı: <strong>{tam_adi}</strong>
              </div>
            </Row>
          </Column>
          <Column width="33%" className={`transfer-item`}>
            <Row className="transfer-item-field">
              <div>
                İşlem <strong>{sure}</strong> dk önce başladı...
              </div>
            </Row>
            <Row className="transfer-item-field">
              <div>
                Banka: <strong>{banka}</strong>
              </div>
            </Row>
            <Row className="transfer-item-field">
              <div>
                Miktar: <strong>{miktar} TL</strong>
              </div>
            </Row>
          </Column>
          <Column width="33%" className={`transfer-item`}>
            <Row className="transfer-item-field">
              <div>
                Durum: <strong>{TransferItemStatus[durum]}</strong>
              </div>
            </Row>
            {verilen_banka && (
              <Row className="transfer-item-field">
                <div>
                  Verilen banka: <strong>{verilen_banka}</strong>
                </div>
              </Row>
            )}
            {backuser && (
              <Row className="transfer-item-field">
                <div>
                  Backuser: <strong>{backuser}</strong>
                </div>
              </Row>
            )}
          </Column>
        </Row>
      </Box>
    </Row>
  );
};

export default TransferItem;
