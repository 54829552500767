import React, { useEffect } from 'react';
import { useActions, useSelector } from '../../redux/hooks';
import { useAsyncTimeout } from '../../utils/utils';

const NewWithdrawPaparaTransferCheck: React.FC = () => {
  const {
    global: { newWithdrawPaparaTransferCreated, newWithdrawPaparaTransferStatusChanged },
    auth: {
      currentUser: { role_yonetim_listesi },
      userLoaded,
    },
  } = useSelector((state) => state);

  const {
    setSoundPlaying,
    getNewWithdrawPaparaTransfersThunk,
    setNewWithdrawPaparaTransferCreated,
    setNewWithdrawPaparaTransferStatusChanged,
  } = useActions();

  const [_, __, restartTimeout] = useAsyncTimeout(
    async () => {
      if (
        role_yonetim_listesi['Yeni Papara Yatırımları / Sonuçlandırma'] ===
          '1' &&
        userLoaded
      ) {
        await getNewWithdrawPaparaTransfersThunk();
        restartTimeout();
      }
    },
    3000,
    [role_yonetim_listesi, userLoaded]
  );

  useEffect(() => {
    if (
      (newWithdrawPaparaTransferCreated || newWithdrawPaparaTransferStatusChanged) &&
      role_yonetim_listesi['Yeni Papara Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1'
    ) {
      if (newWithdrawPaparaTransferStatusChanged) {
        setSoundPlaying('update');
      } else if (newWithdrawPaparaTransferCreated) {
        setSoundPlaying('bip');
        document.title = 'Yeni Papara Çekim isteği...';
      }
    }
  }, [
    newWithdrawPaparaTransferCreated,
    newWithdrawPaparaTransferStatusChanged,
    role_yonetim_listesi,
  ]);


  useAsyncTimeout(
    () => {
      if (newWithdrawPaparaTransferCreated || newWithdrawPaparaTransferStatusChanged) {
        setNewWithdrawPaparaTransferCreated(false);
        setNewWithdrawPaparaTransferStatusChanged(false);
      }
      document.title = 'Backoffice | Kralpay';
    },
    2000,
    [newWithdrawPaparaTransferCreated, newWithdrawPaparaTransferStatusChanged]
  );

  return <></>;
};

export default NewWithdrawPaparaTransferCheck;
