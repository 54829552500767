import React, { useEffect } from 'react';
import { useActions, useSelector } from '../../redux/hooks';
import { useAsyncTimeout } from '../../utils/utils';

const NewWithdrawBankTransferCheck: React.FC = () => {
  const {
    global: { newWithdrawBankTransferCreated, newWithdrawBankTransferStatusChanged },
    auth: {
      currentUser: { role_yonetim_listesi },
      userLoaded,
    },
  } = useSelector((state) => state);

  const {
    setSoundPlaying,
    getNewWithdrawBankTransfersThunk,
    setNewWithdrawBankTransferCreated,
  } = useActions();

  const [_, __, restartTimeout] = useAsyncTimeout(
    async () => {
      if (
        role_yonetim_listesi['Yeni Havale Yatırımları / Sonuçlandırma'] ===
          '1' &&
        userLoaded
      ) {
        await getNewWithdrawBankTransfersThunk();
        restartTimeout();
      }
    },
    3000,
    [role_yonetim_listesi, userLoaded]
  );

  useEffect(() => {
    if (
      role_yonetim_listesi['Yeni Havale Yatırımları / Sonuçlandırma'] === '1' &&
      userLoaded
    ) {
      getNewWithdrawBankTransfersThunk();
    }
  }, [role_yonetim_listesi, userLoaded]);

  useEffect(() => {
    if (
      (newWithdrawBankTransferCreated) &&
      role_yonetim_listesi['Yeni Havale Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1'
    ) {
      console.log('Something is wrong here');
      console.log(newWithdrawBankTransferCreated);
      if (newWithdrawBankTransferCreated) {
        setSoundPlaying('bip');
        document.title = 'Yeni Havale Çekim isteği...';
      }
    }
  }, [
    newWithdrawBankTransferCreated,
    role_yonetim_listesi,
  ]);


  useAsyncTimeout(
    () => {
      if (newWithdrawBankTransferCreated) {
        setNewWithdrawBankTransferCreated(false);
      }
      document.title = 'Backoffice | Kralpay';
    },
    2000,
    [newWithdrawBankTransferCreated, newWithdrawBankTransferStatusChanged]
  );

  return <></>;
};

export default NewWithdrawBankTransferCheck;
