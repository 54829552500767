import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import Row from '../../components/utils/Row/Row';
import React, { ReactNode, useEffect, useState } from 'react';
import DatePicker from '../../components/DatePicker/DatePicker';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import Button, { Color, Icon } from '../../components/Button/Button';
import { useActions, useSelector } from '../../redux/hooks';
import { SelectEntry } from '../../components/MultiSelect/types';
import useDataTable from '../../components/DataTable/DataTable';
import LabeledContainer from '../../components/LabeledContainer/LabeledContainer';
import { OrderType } from '../../components/DataTable/types';

const Reports = () => {

  const {
    auth: {
      currentUser: { role_yonetim_listesi },
    },
  } = useSelector((state) => state);

  const { merchantSelectList, reportList, reportListLoading } = useSelector(
    (state) => state.global
  );
  const [selectedMerchants, setSelectedMerchants] = useState<SelectEntry[]>([]);
  const { getReportsThunk } = useActions();

  const [tarih, setDateRange] = useState('');

  const [pagination, setPagination] = useState(10);
  const [page, setCurrentPage] = useState(1);
  const [column, setOrderColumn] = useState('');
  const [order, setOrderType] = useState<OrderType>('');
  const [merchantCommaList, setMerchantCommaList] = useState('');

  useEffect(() => {
    if (merchantSelectList.length > 0) setSelectedMerchants(merchantSelectList);
  }, [merchantSelectList]);

  useEffect(() => {
    setMerchantCommaList(selectedMerchants.map((e) => e.id).join(','));
  }, [selectedMerchants]);

  const onColumnRender = (
    value: ReactNode,
    index: number,
    row: React.ReactNode[]
  ) => {
    const arrayLength = row.length;
    if (index == arrayLength - 1) {
      return value;
    }
    if ([2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].includes(index)) {
      return value + ' TL';
    }
  };

  const fetchReportList = () => {
    getReportsThunk({
      tarih: tarih ? tarih.split(' - ').join(',') : '',
      merchant: merchantCommaList,
      page,
      pageCount: pagination,
      column,
      order,
    });
  };

  useEffect(() => {
    fetchReportList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, column, order, pagination]);

  const [itWorks, setItWorks] = useState(false);

  useEffect(() => {
    // reference must be same for addEventListener and removeEventListener
    // = can't use inline arrow function!
    function listenerReports(event: { code: string; }) {
      if (event.code === 'Enter') {
        setItWorks(!itWorks);
        fetchReportList();
      }
    }
    fetchReportList();

    document.addEventListener('keypress', listenerReports);
    return () => {
      document.removeEventListener('keypress', listenerReports);
    }
  }, [!itWorks]);

  const DataTable = useDataTable<10>();

  return (
    <Row width="100%">
      <Box
        boxTitle={
          <div style={{ textDecoration: 'underline' }}>
            <h3>
              <strong>Raporlar</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          <Row width="100%" padding="0" height="200px">
            <Column width="50%">
              <Row width="100%">
                <Column width="100%">
                  <LabeledContainer label="Tarih Aralığı">
                    <DatePicker
                      value={tarih}
                      onChange={(startDate, endDate) => {
                        if (startDate && endDate)
                          setDateRange(startDate + ' - ' + endDate);
                        else setDateRange('');
                      }}
                    />
                  </LabeledContainer>
                </Column>
              </Row>
            </Column>
            <Column width="50%">
              <Row>
                <MultiSelect
                  value={selectedMerchants}
                  height="90px"
                  onChange={(selectedElements) => {
                    setSelectedMerchants(selectedElements);
                  }}
                  list={merchantSelectList}
                />
              </Row>
              <Row>
                <Button
                  onClick={() => {
                    setItWorks(!itWorks);
                    if (page === 1) fetchReportList();
                    setCurrentPage(1);
                  }}
                  color={Color.orange}
                  icon={Icon.search}
                  loading={reportListLoading}
                >
                  Filtrele
                </Button>
              </Row>
            </Column>
          </Row>

          <Row width="100%">
            <DataTable
              onPageUpdate={(pageNumber, orderColumn, orderType, pagination) => {
                setCurrentPage(pageNumber);
                setOrderColumn(orderColumn);
                setOrderType(orderType);
                setPagination(pagination);
              }}
              value={reportList}
              loading={reportListLoading}
              page={page}
              pagination={pagination}
              onColumnRender={onColumnRender}
              download={true}
              addRows={(rows: ReactNode[][]) => {
                if (rows.length === 0) return [];

                const rowsSum = rows.reduce((acc, row) => {
                  return row.map((e, i) => (e as number) + (acc[i] as number));
                }, new Array(rows[0].length).fill(0));

                const renderRows: ReactNode[][] = [];

                // Add an array representing a row with empty elements
                renderRows.push(['', '']);

                // Add dynamically generated elements using a loop to the same row
                for (let index = 2; index < rowsSum.length - 1; index++) {
                  renderRows[0].push(
                    <b key={index}>
                      <i>{rowsSum[index].toFixed(2) + ' TL'}</i>
                    </b>
                  );
                }

                renderRows.push(['']);

                return renderRows;
              }}
            />
          </Row>

        </Column>
      </Box>
    </Row>
  );
};

export default Reports;
