import { createAsyncThunk } from '@reduxjs/toolkit';
import { SelectEntry } from '../../../components/MultiSelect/types';
import { makeRequestThunk } from '../../../utils/request';

export interface UserEntry {
  id: number;
  name: string;
}

export const getUserGroupListThunk = createAsyncThunk(
  'global/getUserGroupList',
  async (_, { rejectWithValue }): Promise<SelectEntry[]> => {
    const {
      data: { results },
      rejected,
    } = await makeRequestThunk<{ results: UserEntry[] }>(
      {
        method: 'get',
        url: '/api/userGroups',
        params: {
          pageCount: 100000,
        },
      },
      rejectWithValue
    );

    if (rejected) return rejected;

    return results
      .map((e) => ({
        id: e.id,
        text: e.name,
      }))
      .sort((a, b) => a.id - b.id);
  }
);
