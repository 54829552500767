import './Menu.scss';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useActions, useSelector } from '../../redux/hooks';
import Container from '../utils/Container/Container';
import MenuList from './MenuList';

export const Menu: React.FC = () => {
  const { toggleMenu } = useActions();

  const {
    menu: { isLocked },
    auth: { availableRoutes, routesLoaded },
  } = useSelector((state) => state);
  const [isHovering, setIsHovering] = useState(false);

  return (
    <Container>
      {routesLoaded && (
        <div
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          className={`menu ${isLocked || isHovering ? 'open' : ''} ${
            isLocked ? 'locked' : ''
          }`}
        >
          <div className="header">
            <Link to={availableRoutes[0]} className="image">
              <img src={process.env.PUBLIC_URL + '/logo.png'} />
              <img src={process.env.PUBLIC_URL + '/logo-light.png'} />
            </Link>
            <div className="toggle" onClick={() => toggleMenu()}>
              <div className="toggle-icon">
                <i className="las la-bars" />
              </div>
              <div className="lock-status">
                <i className={'las la-' + (isLocked ? 'lock' : 'unlock')} />
              </div>
            </div>
          </div>
          <ul>
            <MenuList />
          </ul>
        </div>
      )}
    </Container>
  );
};
