import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { InfoMessageIconKey } from '../../components/InfoMessage/InfoMessage';
import { getFraudsThunk } from '../thunks/Fraud/getFraudsThunk';
import { getBankSetThunk } from '../thunks/BanksSet/getBankSetThunk';
import { getCryptoRegistersThunk } from '../thunks/CryptoRegisters/getCryptoRegistersThunk';
import { getMFTRegistersThunk } from '../thunks/MFTRegister/getMFTRegistersThunk';
import { getPaparaRegistersThunk } from '../thunks/PaparaRegister/getPaparaRegistersThunk';
import { getBankRegistersThunk } from '../thunks/BankRegister/getBankRegistersThunk';
import { getRolesThunk } from '../thunks/Role/getRolesThunk';
import { getUsersThunk } from '../thunks/User/getUsersThunk';
import { getMerchantsThunk } from '../thunks/Merchant/getMerchantsThunk';
import { getDepositsThunk } from '../thunks/Deposit/getDepositsThunk';
import { getWithdrawsBankThunk } from '../thunks/WithdrawBank/getWithdrawsBankThunk';
import { getWithdrawsPaparaThunk } from '../thunks/WithdrawPapara/getWithdrawsPaparaThunk';
import { getWithdrawsCryptoThunk } from '../thunks/WithdrawCrypto/getWithdrawsCryptoThunk';

import { getPaparaMailsThunk } from '../thunks/PaparaMail/getPaparaMailsThunk';
import { getReportsThunk } from '../thunks/Report/getReportsThunk';
import { getBanksThunk } from '../thunks/Bank/getBanksThunk';
import { getCryptoRegisterDetailsThunk } from '../thunks/CryptoRegisters/getCryptoRegisterDetailsThunk';
import { getMFTRegisterDetailsThunk } from '../thunks/MFTRegister/getMFTRegisterDetailsThunk';
import { getPaparaRegisterDetailsThunk } from '../thunks/PaparaRegister/getPaparaRegisterDetailsThunk';
import { getBankRegisterDetailsThunk } from '../thunks/BankRegister/getBankRegisterDetailsThunk';
import { getUserInfoThunk } from '../thunks/User/getUserInfoThunk';
import { getBankRegisterInfoThunk } from '../thunks/BankRegister/getBankRegisterInfoThunk';
import { getPaparaRegisterInfoThunk } from '../thunks/PaparaRegister/getPaparaRegisterInfoThunk';
import { getCryptoRegisterInfoThunk } from '../thunks/CryptoRegisters/getCryptoRegisterInfoThunk';
import { getMFTRegisterInfoThunk } from '../thunks/MFTRegister/getMFTRegisterInfoThunk';
import { getMerchantInfoThunk } from '../thunks/Merchant/getMerchantInfoThunk';
import { getRoleInfoThunk } from '../thunks/Role/getRoleInfoThunk';
import { getMerchantListThunk } from '../thunks/Merchant/getMerchantListThunk';
import { getWholeMerchantListThunk } from '../thunks/Merchant/getWholeMerchantListThunk';
import { getPaparaRegisterListThunk } from '../thunks/PaparaRegister/getPaparaRegisterListThunk';
import { getMFTRegisterListThunk } from '../thunks/MFTRegister/getMFTRegisterListThunk';
import { getCryptoRegisterListThunk } from '../thunks/CryptoRegisters/getCryptoRegisterListThunk';
import { getBankRegisterListThunk } from '../thunks/BankRegister/getBankRegisterListThunk';
import { getBankListThunk } from '../thunks/Bank/getBankListThunk';
import { getRoleListThunk } from '../thunks/Role/getRoleListThunk';
import { getCurrentPaparaListThunk } from '../thunks/CurrentPaparaList/getCurrentPaparaListThunk';
import { getWithdrawBankInfoThunk } from '../thunks/WithdrawBank/getWithdrawBankInfoThunk';
import { getWithdrawPaparaInfoThunk } from '../thunks/WithdrawPapara/getWithdrawPaparaInfoThunk';
import { getWithdrawCryptoInfoThunk } from '../thunks/WithdrawCrypto/getWithdrawCryptoInfoThunk';
import { getCurrentUserThunk } from '../thunks/User/getCurrentUserThunk';
import { getDepositInfoThunk } from '../thunks/Deposit/getDepositInfoThunk';
import { getDashboardInfoThunk } from '../thunks/Dashboard/getDashboardInfoThunk';
import { getNewTransferBankRegistersThunk } from '../thunks/NewTransfer/getNewTransferBankRegistersThunk';
import { getNewTransferInfoThunk } from '../thunks/NewTransfer/getNewTransferInfoThunk';
import { loginThunk } from '../thunks/loginThunk';
import { getNewTransfersThunk } from '../thunks/NewTransfer/getNewTransfersThunk';

export interface notificationState {
  message: string;
  showMessage: boolean;
  icon: InfoMessageIconKey;
  isLoading: boolean;
  activeSound: 'bip' | 'update' | '';
  currentRoute: string;
}

export interface showMessageParams {
  message: string;
  icon: InfoMessageIconKey;
}

const initialState: notificationState = {
  message: '',
  showMessage: false,
  icon: 'information',
  isLoading: false,
  activeSound: '',
  currentRoute: '',
};

export const notificationSlice = createSlice({
  name: 'notificationSlice',
  initialState,
  reducers: {
    showMessage: (state, action: PayloadAction<showMessageParams>) => {
      const { message, icon } = action.payload;
      state.message = message;
      state.icon = icon;
      state.showMessage = true;
    },
    setMessageStatus: (state, action: PayloadAction<boolean>) => {
      state.showMessage = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSoundPlaying: (state, action: PayloadAction<'bip' | 'update' | ''>) => {
      state.activeSound = action.payload;
    },
    setNotificationCurrentRoute: (state, action: PayloadAction<string>) => {
      state.currentRoute = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserInfoThunk.rejected, (state, action: any) => {
      if (action.payload.data?.error_message) {
        state.message = action.payload.data?.error_message;
        state.icon = 'warning';
        state.showMessage = true;
      } else {
        state.message =
          'Kullanıcı bilgileri yüklenirken bir hata ile karşılaşıldı';
        state.icon = 'warning';
        state.showMessage = true;
      }
    });
    builder.addCase(getBankRegisterInfoThunk.rejected, (state, action: any) => {
      if (action.payload.data?.error_message) {
        state.message = action.payload.data?.error_message;
        state.icon = 'warning';
        state.showMessage = true;
      } else {
        state.message =
          'Banka kasa bilgileri yüklenirken bir hata ile karşılaşıldı';
        state.icon = 'warning';
        state.showMessage = true;
      }
    });
    builder.addCase(
      getPaparaRegisterInfoThunk.rejected,
      (state, action: any) => {
        if (action.payload.data?.error_message) {
          state.message = action.payload.data?.error_message;
          state.icon = 'warning';
          state.showMessage = true;
        } else {
          state.message =
            'Papara kasa bilgileri yüklenirken bir hata ile karşılaşıldı';
          state.icon = 'warning';
          state.showMessage = true;
        }
      }
    );
    builder.addCase(
      getCryptoRegisterInfoThunk.rejected,
      (state, action: any) => {
        if (action.payload.data?.error_message) {
          state.message = action.payload.data?.error_message;
          state.icon = 'warning';
          state.showMessage = true;
        } else {
          state.message =
            'Kripto kasa bilgileri yüklenirken bir hata ile karşılaşıldı';
          state.icon = 'warning';
          state.showMessage = true;
        }
      }
    );
    builder.addCase(
      getMFTRegisterInfoThunk.rejected,
      (state, action: any) => {
        if (action.payload.data?.error_message) {
          state.message = action.payload.data?.error_message;
          state.icon = 'warning';
          state.showMessage = true;
        } else {
          state.message =
            'MFT kasa bilgileri yüklenirken bir hata ile karşılaşıldı';
          state.icon = 'warning';
          state.showMessage = true;
        }
      }
    );
    builder.addCase(getMerchantInfoThunk.rejected, (state, action: any) => {
      if (action.payload.data?.error_message) {
        state.message = action.payload.data?.error_message;
        state.icon = 'warning';
        state.showMessage = true;
      } else {
        state.message =
          'Merchant bilgileri yüklenirken bir hata ile karşılaşıldı';
        state.icon = 'warning';
        state.showMessage = true;
      }
    });
    builder.addCase(getRoleInfoThunk.rejected, (state, action: any) => {
      if (action.payload.data?.error_message) {
        state.message = action.payload.data?.error_message;
        state.icon = 'warning';
        state.showMessage = true;
      } else {
        state.message = 'Rol bilgileri yüklenirken bir hata ile karşılaşıldı';
        state.icon = 'warning';
        state.showMessage = true;
      }
    });
    builder.addCase(
      getCurrentPaparaListThunk.rejected,
      (state, action: any) => {
        if (action.payload.data?.error_message) {
          state.message = action.payload.data?.error_message;
          state.icon = 'warning';
          state.showMessage = true;
        } else {
          state.message =
            'Güncel paparalar yüklenirken bir hata ile karşılaşıldı';
          state.icon = 'warning';
          state.showMessage = true;
        }
      }
    );
    builder.addCase(getWithdrawBankInfoThunk.rejected, (state, action: any) => {
      if (action.payload.data?.error_message) {
        state.message = action.payload.data?.error_message;
        state.icon = 'warning';
        state.showMessage = true;
      } else {
        state.message = 'Çekim detayları yüklenirken bir hata ile karşılaşıldı';
        state.icon = 'warning';
        state.showMessage = true;
      }
    });
    builder.addCase(getWithdrawPaparaInfoThunk.rejected, (state, action: any) => {
      if (action.payload.data?.error_message) {
        state.message = action.payload.data?.error_message;
        state.icon = 'warning';
        state.showMessage = true;
      } else {
        state.message = 'Çekim detayları yüklenirken bir hata ile karşılaşıldı';
        state.icon = 'warning';
        state.showMessage = true;
      }
    });
    builder.addCase(getWithdrawCryptoInfoThunk.rejected, (state, action: any) => {
      if (action.payload.data?.error_message) {
        state.message = action.payload.data?.error_message;
        state.icon = 'warning';
        state.showMessage = true;
      } else {
        state.message = 'Çekim detayları yüklenirken bir hata ile karşılaşıldı';
        state.icon = 'warning';
        state.showMessage = true;
      }
    });
    builder.addCase(getDepositInfoThunk.rejected, (state, action: any) => {
      if (action.payload.data?.error_message) {
        state.message = action.payload.data?.error_message;
        state.icon = 'warning';
        state.showMessage = true;
      } else {
        state.message =
          'Yatırım detayları yüklenirken bir hata ile karşılaşıldı';
        state.icon = 'warning';
        state.showMessage = true;
      }
    });
    builder.addCase(getNewTransfersThunk.rejected, (state, action: any) => {
      if (state.currentRoute === '/yenihavale') {
        if (action.payload.code === 'ERR_NETWORK') {
          state.message =
            'İnternet bağlantınızı kontrol edip lütfen tekrar deneyin.';
          state.icon = 'warning';
          state.showMessage = true;
        } else if (action.payload.data?.error_message) {
          state.message = action.payload.data?.error_message;
          state.icon = 'warning';
          state.showMessage = true;
        } else {
          state.message =
            'Yeni havale yatırımları yüklenirken bir hata ile karşılaşıldı';
          state.icon = 'warning';
          state.showMessage = true;
        }
      }
    });
    builder.addCase(getNewTransferInfoThunk.rejected, (state, action: any) => {
      if (action.payload.data?.error_message) {
        state.message = action.payload.data?.error_message;
        state.icon = 'warning';
        state.showMessage = true;
      } else {
        state.message =
          'Yeni havale yatırım detayları yüklenirken bir hata ile karşılaşıldı';
        state.icon = 'warning';
        state.showMessage = true;
      }
    });
    builder.addCase(getDashboardInfoThunk.rejected, (state, action: any) => {
      if (action.payload.data?.error_message) {
        state.message = action.payload.data?.error_message;
        state.icon = 'warning';
        state.showMessage = true;
      } else {
        state.message =
          'Dashboard bilgileri yüklenirken bir hata ile karşılaşıldı';
        state.icon = 'warning';
        state.showMessage = true;
      }
    });
    builder.addCase(loginThunk.rejected, (state, action: any) => {
      if (action.payload.data?.error_message) {
        state.message = action.payload.data?.error_message;
        state.icon = 'warning';
        state.showMessage = true;
      } else {
        state.message = 'Giriş başarısız';
        state.icon = 'warning';
        state.showMessage = true;
      }
    });
    builder.addMatcher(
      isAnyOf(
        getFraudsThunk.rejected,
        getRolesThunk.rejected,
        getUsersThunk.rejected,
        getMerchantsThunk.rejected,
        getDepositsThunk.rejected,
        getWithdrawsBankThunk.rejected,
        getWithdrawsPaparaThunk.rejected,
        getWithdrawsCryptoThunk.rejected,
        getPaparaMailsThunk.rejected,
        getReportsThunk.rejected,
        getCryptoRegistersThunk.rejected,
        getMFTRegistersThunk.rejected,
        getPaparaRegistersThunk.rejected,
        getBanksThunk.rejected,
        getPaparaRegisterDetailsThunk.rejected,
        getBankRegisterDetailsThunk.rejected,
        getBankRegistersThunk.rejected,
        getCryptoRegisterDetailsThunk.rejected,
        getNewTransferBankRegistersThunk.rejected,
        getBankSetThunk.rejected,
        getMFTRegisterDetailsThunk.rejected
      ),
      (state, action: any) => {
        if (action.payload.data?.error_message) {
          state.message = action.payload.data?.error_message;
          state.icon = 'warning';
          state.showMessage = true;
        } else {
          state.message = 'Tablo yüklenirken bir hata ile karşılaşıldı';
          state.icon = 'warning';
          state.showMessage = true;
        }
      }
    );
    builder.addMatcher(
      isAnyOf(
        getMerchantListThunk.rejected,
        getWholeMerchantListThunk.rejected,
        getCryptoRegisterListThunk.rejected,
        getPaparaRegisterListThunk.rejected,
        getMFTRegisterListThunk.rejected,
        getBankRegisterListThunk.rejected,
        getBankListThunk.rejected,
        getRoleListThunk.rejected,
        getCurrentUserThunk.rejected
      ),
      (state, action: any) => {
        if (action.payload.data?.error_message) {
          state.message = action.payload.data?.error_message;
          state.icon = 'warning';
          state.showMessage = true;
        } else {
          state.message = 'Sistem hatası';
          state.icon = 'warning';
          state.showMessage = true;
        }
      }
    );
    builder.addMatcher(
      isAnyOf(
        loginThunk.rejected,
        getFraudsThunk.rejected,
        getBankSetThunk.rejected,
        getRolesThunk.rejected,
        getUsersThunk.rejected,
        getMerchantsThunk.rejected,
        getDepositsThunk.rejected,
        getWithdrawsBankThunk.rejected,
        getWithdrawsPaparaThunk.rejected,
        getWithdrawsCryptoThunk.rejected,
        getPaparaMailsThunk.rejected,
        getReportsThunk.rejected,
        getCryptoRegistersThunk.rejected,
        getPaparaRegistersThunk.rejected,
        getBanksThunk.rejected,
        getCryptoRegisterDetailsThunk.rejected,
        getPaparaRegisterDetailsThunk.rejected,
        getBankRegisterDetailsThunk.rejected,
        getMFTRegisterDetailsThunk.rejected,
        getBankRegistersThunk.rejected,
        getCryptoRegisterInfoThunk.rejected,
        getNewTransferBankRegistersThunk.rejected,
        getMerchantListThunk.rejected,
        getWholeMerchantListThunk.rejected,
        getCryptoRegisterListThunk.rejected,
        getPaparaRegisterListThunk.rejected,
        getMFTRegisterListThunk.rejected,
        getMFTRegistersThunk.rejected,
        getBankRegisterListThunk.rejected,
        getBankListThunk.rejected,
        getRoleListThunk.rejected,
        getCurrentUserThunk.rejected,
        getUserInfoThunk.rejected,
        getBankRegisterInfoThunk.rejected,
        getPaparaRegisterInfoThunk.rejected,
        getMFTRegisterInfoThunk.rejected,
        getMerchantInfoThunk.rejected,
        getRoleInfoThunk.rejected,
        getCurrentPaparaListThunk.rejected,
        getWithdrawBankInfoThunk.rejected,
        getDepositInfoThunk.rejected,
        getNewTransferInfoThunk.rejected,
        getDashboardInfoThunk.rejected
      ),
      (state, action: any) => {
        if (action.payload.code === 'ERR_NETWORK') {
          state.message =
            'İnternet bağlantınızı kontrol edip lütfen tekrar deneyin.';
          state.icon = 'warning';
          state.showMessage = true;
        }
      }
    );
  },
});

export const actions = notificationSlice.actions;

export default notificationSlice.reducer;
