import menuItems from './menu-items';
import { Link, matchPath } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from '../../redux/hooks';
import { useAsyncTimeout } from '../../utils/utils';


const MenuList: React.FC = () => {
  
  const {
    auth: {
      availableRoutes,
      currentRoute,
      currentUser: { role_yonetim_listesi },
    },
    global: {
      newTransferCreated,
      newTransferStatusChanged,
      newCryptoTransferCreated,
      newCryptoTransferStatusChanged,
      newPaparaTransferCreated,
      newPaparaTransferStatusChanged,
      newMFTTransferCreated,
      newMFTTransferStatusChanged,
      newWithdrawBankTransferCreated,
      newWithdrawBankTransferStatusChanged,
      newWithdrawPaparaTransferCreated,
      newWithdrawPaparaTransferStatusChanged,
      newWithdrawMFTTransferCreated,
      newWithdrawMFTTransferStatusChanged,
      newWithdrawCryptoTransferCreated,
      newWithdrawCryptoTransferStatusChanged
    },
  } = useSelector((state) => state);
  
  const [flashButton, setFlashButton] = useState(false);
  const [flashPaparaButton, setFlashPaparaButton] = useState(false);
  const [flashCryptoButton, setFlashCryptoButton] = useState(false);
  const [flashMFTButton, setFlashMFTButton] = useState(false);

  const [flashWithdrawBankButton, setFlashWithdrawBankButton] = useState(false);
  const [flashWithdrawPaparaButton, setFlashWithdrawPaparaButton] = useState(false);
  const [flashWithdrawCryptoButton, setFlashWithdrawCryptoButton] = useState(false);
  const [flashWithdrawMFTButton, setFlashWithdrawMFTButton] = useState(false);
  
  const { 
    newTransferList, newPaparaTransferList, newCryptoTransferList, newMFTTransferList,
    newWithdrawBankTransferList, newWithdrawPaparaTransferList, newWithdrawCryptoTransferList, newWithdrawMFTTransferList 
  } = useSelector((state) => state.global);

  useEffect(() => {
    if (
      (newTransferCreated || newTransferStatusChanged) &&
      role_yonetim_listesi['Yeni Havale Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1' &&
      !flashButton
    ) {
      setFlashButton(true);
    }
  }, [
    newTransferCreated,
    newTransferStatusChanged,
    role_yonetim_listesi,
    flashButton,
  ]);

  useEffect(() => {
    if (
      (newPaparaTransferCreated || newPaparaTransferStatusChanged) &&
      role_yonetim_listesi['Yeni Papara Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1' &&
      !flashPaparaButton
    ) {
      setFlashPaparaButton(true);
    }
  }, [
    newPaparaTransferCreated,
    newPaparaTransferStatusChanged,
    role_yonetim_listesi,
    flashPaparaButton,
  ]);

  useEffect(() => {
    if (
      (newCryptoTransferCreated || newCryptoTransferStatusChanged) &&
      role_yonetim_listesi['Yeni Kripto Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1' &&
      !flashCryptoButton
    ) {
      setFlashCryptoButton(true);
    }
  }, [
    newCryptoTransferCreated,
    newCryptoTransferStatusChanged,
    role_yonetim_listesi,
    flashCryptoButton,
  ]);

  useEffect(() => {
    if (
      (newMFTTransferCreated || newMFTTransferStatusChanged) &&
      role_yonetim_listesi['Yeni MFT Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1' &&
      !flashMFTButton
    ) {
      setFlashMFTButton(true);
    }
  }, [
    newMFTTransferCreated,
    newMFTTransferStatusChanged,
    role_yonetim_listesi,
    flashMFTButton,
  ]);

  useEffect(() => {
    if (
      (newWithdrawBankTransferCreated || newWithdrawBankTransferStatusChanged) &&
      role_yonetim_listesi['Yeni Havale Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1' &&
      !flashWithdrawBankButton
    ) {
      setFlashWithdrawBankButton(true);
    }
  }, [
    newWithdrawBankTransferCreated,
    newWithdrawBankTransferStatusChanged,
    role_yonetim_listesi,
    flashWithdrawBankButton,
  ]);


  useEffect(() => {
    if (
      (newWithdrawPaparaTransferCreated || newWithdrawPaparaTransferStatusChanged) &&
      role_yonetim_listesi['Yeni Papara Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1' &&
      !flashPaparaButton
    ) {
      setFlashWithdrawPaparaButton(true);
    }
  }, [
    newWithdrawPaparaTransferCreated,
    newWithdrawPaparaTransferStatusChanged,
    role_yonetim_listesi,
    flashWithdrawPaparaButton,
  ]);

  useEffect(() => {
    if (
      (newWithdrawCryptoTransferCreated || newWithdrawCryptoTransferStatusChanged) &&
      role_yonetim_listesi['Yeni Kripto Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1' &&
      !flashWithdrawCryptoButton
    ) {
      setFlashWithdrawCryptoButton(true);
    }
  }, [
    newWithdrawCryptoTransferCreated,
    newWithdrawCryptoTransferStatusChanged,
    role_yonetim_listesi,
    flashWithdrawCryptoButton,
  ]);

  useEffect(() => {
    if (
      (newWithdrawMFTTransferCreated || newWithdrawMFTTransferStatusChanged) &&
      role_yonetim_listesi['Yeni MFT Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1' &&
      !flashWithdrawMFTButton
    ) {
      setFlashWithdrawMFTButton(true);
    }
  }, [
    newWithdrawMFTTransferCreated,
    newWithdrawMFTTransferStatusChanged,
    role_yonetim_listesi,
    flashWithdrawMFTButton,
  ]);


  useAsyncTimeout(
    () => {
      setFlashButton(false);
    },
    2000,
    [flashButton]
  );

  useAsyncTimeout(
    () => {
      setFlashPaparaButton(false);
    },
    2000,
    [flashPaparaButton]
  );

  useAsyncTimeout(
    () => {
      setFlashCryptoButton(false);
    },
    2000,
    [flashCryptoButton]
  );

  useAsyncTimeout(
    () => {
      setFlashMFTButton(false);
    },
    2000,
    [flashMFTButton]
  );

  useAsyncTimeout(
    () => {
      setFlashWithdrawBankButton(false);
    },
    2000,
    [flashWithdrawBankButton]
  );

  useAsyncTimeout(
    () => {
      setFlashWithdrawPaparaButton(false);
    },
    2000,
    [flashWithdrawPaparaButton]
  );

  useAsyncTimeout(
    () => {
      setFlashWithdrawCryptoButton(false);
    },
    2000,
    [flashWithdrawCryptoButton]
  );

  useAsyncTimeout(
    () => {
      setFlashWithdrawMFTButton(false);
    },
    2000,
    [flashWithdrawMFTButton]
  );

  return useMemo(() => {
    return (
      <>
        {menuItems
          .filter(({ route }) => availableRoutes.includes(route))
          .map(({ route, icon, label }) => (
            <li
              className={
                (matchPath(route, currentRoute) ? 'active' : '') +
                ((flashButton && route === 'yenihavale') ||
                (flashPaparaButton && route === 'yenipapara') ||
                (flashCryptoButton && route === 'yenikripto') ||
                (flashCryptoButton && route === 'yenimft')
                  ? ' flash'
                  : '')
              }
              key={route}
            >
              <Link to={`/${route}`} className="button">
                <i className={`icon ${icon}`} />
                <span className="label">{label}</span>
                {
                  label == 'Yeni Havale Yatırımları' ? <span className="circle" id="yty">{newTransferList.length}</span>: 
                  (label == 'Yeni Papara Yatırımları' ? <span className="circle" id="ypy">{newPaparaTransferList.length}</span>: 
                    (
                      label == 'Yeni Kripto Yatırımları' ? <span className="circle" id="ypy">{newCryptoTransferList.length}</span>: 
                      (
                        label == 'Yeni MFT Yatırımları' ? <span className="circle" id="ypy">{newMFTTransferList.length}</span>: 
                        (
                          label == 'Havale Çekimler' ? <span className="circle" id="ypy">{newWithdrawBankTransferList.length}</span>: 
                          (
                            label == 'Papara Çekimler' ? <span className="circle" id="ypy">{newWithdrawPaparaTransferList.length}</span>: 
                            (
                              label == 'Kripto Çekimler' ? <span className="circle" id="ypy">{newWithdrawCryptoTransferList.length}</span>: 
                              (
                                label == 'MFT Çekimler' ? <span className="circle" id="ypy">{newWithdrawMFTTransferList.length}</span>: ""
                              )
                            )
                          )
                        )
                      ) 
                    )
                  )
                }
              </Link>
            </li>
          ))}
      </>
    );
  }, [availableRoutes, currentRoute, 
    flashButton, flashPaparaButton, flashCryptoButton, flashMFTButton,
    flashWithdrawBankButton, flashWithdrawPaparaButton, flashWithdrawCryptoButton, flashWithdrawMFTButton,
    newTransferList, newPaparaTransferList, newCryptoTransferList, newMFTTransferList,
    newWithdrawBankTransferList, newWithdrawPaparaTransferList, newWithdrawCryptoTransferList, newWithdrawMFTTransferList]);
};

export default MenuList;
