export interface DashboardFilterParams {
  tarih_araligi: string;
  merchants: string;
}

interface Money {
  Toplam: string;
  Papara: string;
  Havale: string;
  Kripto: string;
  MFT: string;
}

export interface UserPerformance {
  isim: string;
  hesap_vermek: string;
  yatirim_onay: string;
  yatirim_redd: string;
  cekim_onay: string;
  cekim_redd: string;
  toplam: string;
}

export interface DashboardInfoFields {
  context: {
    toplam_yatirim: Money;
    toplam_cekim: Money;
    toplam_komisyon: Money;
    toplam_kalan: Money;
    bakiye_toplami: string;
    papara_kasa_toplami: string;
    genel_kasa_toplami: string;
    kripto_kasa_toplami: string;
    mft_kasa_toplami: string;
    userPerformList: UserPerformance[];
    grafik_info: {
      [key: string]: {
        toplam_yatirim: number;
        toplam_cekim: number;
        toplam_komisyon: number;
        toplam_kalan: number;
      };
    };
  };
}

export const DashboardInfoInitial: DashboardInfoFields = {
  context: {
    toplam_yatirim: {
      Toplam: '',
      Havale: '',
      Papara: '',
      Kripto: '',
      MFT: ''
    },
    toplam_cekim: {
      Toplam: '',
      Havale: '',
      Papara: '',
      Kripto: '',
      MFT: ''
    },
    toplam_komisyon: {
      Toplam: '',
      Havale: '',
      Papara: '',
      Kripto: '',
      MFT: ''
    },
    toplam_kalan: {
      Toplam: '',
      Havale: '',
      Papara: '',
      Kripto: '',
      MFT: ''
    },
    bakiye_toplami: '',
    papara_kasa_toplami: '',
    genel_kasa_toplami: '',
    kripto_kasa_toplami: '',
    mft_kasa_toplami: '',
    userPerformList: [],
    grafik_info: {},
  },
};
