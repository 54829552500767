import React, { ReactNode, useEffect, useState } from 'react';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import { useActions, useSelector } from '../../redux/hooks';
import { SelectEntry } from '../../components/MultiSelect/types';
import LabeledContainer from '../../components/LabeledContainer/LabeledContainer';
import Button, { Color, Icon } from '../../components/Button/Button';
import useDataTable from '../../components/DataTable/DataTable';
import DatePicker from '../../components/DatePicker/DatePicker';
import StatusIcon, {
  StatusIconKey,
} from '../../components/DataTable/StatusIcon';
import { OrderType } from '../../components/DataTable/types';
import Modal from '../../components/Modal/Modal';
import WithdrawDetail from './WithdrawDetail';
import { makeRequest } from '../../utils/request';
import { WithdrawBankInfoFields } from './types';

const Withdraws: React.FC = () => {
  const {
    global: { merchantSelectList, withdrawBankList, withdrawBankListLoading },
    auth: {
      currentUser: { role_yonetim_listesi },
    },
  } = useSelector((state) => state);
  const [selectedMerchants, setSelectedMerchants] = useState<SelectEntry[]>([]);
  const { getWithdrawsBankThunk, getWithdrawBankInfoThunk, setLoading, showMessage } =
    useActions();

  const [currentId, setCurrentId] = useState('');

  const [islem_id, setProcessId] = useState('');
  const [kullanici_id, setUserId] = useState('');
  const [kullanici_adi, setUserName] = useState('');
  const [tam_isim, setFullName] = useState('');
  const [hesap, setAccount] = useState('');
  const [method, setMethod] = useState('Havale');
  const [cekim_durum, setStatus] = useState('');
  const [olusturma, setCreateDate] = useState('');
  const [guncelleme, setUpdateDate] = useState('');
  const [merchantCommaList, setMerchantCommaList] = useState('');

  const [pagination, setPagination] = useState(10);
  const [page, setCurrentPage] = useState(1);
  const [column, setOrderColumn] = useState('');
  const [order, setOrderType] = useState<OrderType>('');

  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  useEffect(() => {
    if (merchantSelectList.length > 0) setSelectedMerchants(merchantSelectList);
  }, [merchantSelectList]);

  useEffect(() => {
    setMerchantCommaList(selectedMerchants.map((e) => e.id).join(','));
  }, [selectedMerchants]);

  const copyWithdrawInfo = async (row: React.ReactNode[]) => {
    const { data, rejected } = await makeRequest<WithdrawBankInfoFields>(
      {
        method: 'get',
        url: `/api/v1/cekim/${(row[1] as string).substring(2)}`,
        params: {
          hidden: true,
        },
      },
      showMessage
    );
    if (!rejected) {
      await window.navigator.clipboard.writeText(
        `Müşteri Tam Adı: ${row[7]}
        Banka İsmi: ${row[8]}
        IBAN: ${data.islem_detayi.hesap_iban}
        TC Kimlik Numarası: ${data.islem_detayi.tckn}
        Doğum Tarihi: ${data.islem_detayi.dogum_tarihi}
        Miktar: ${row[3]}
        Merchant: ${row[2]}
        Method: Çekim`
      );
      showMessage({
        message: 'Bilgiler panoya kopyalandı',
        icon: 'information',
      });
    }
  };

  const onColumnRender = (
    value: ReactNode,
    index: number,
    row: React.ReactNode[]
  ) => {
    if (index === 0) {
      let statusIcon: StatusIconKey = 'approved';
      if (value === 'Onaylandi') {
        statusIcon = 'approved';
      } else if (value === 'Beklemede') {
        statusIcon = 'waiting';
      } else if (value === 'Reddedildi') {
        statusIcon = 'rejected';
      }
      return (
        <Row width="100%" margin="0" padding="0">
          <Column>
            <StatusIcon status={statusIcon} />
          </Column>
        </Row>
      );
    }
    if ([3, 4].includes(index)) {
      return value + ' TL';
    }
    if (index === 11) {
      return (
        <Row width="100%" margin="0" padding="0">
          <Column width="35px" height="35px" margin="0" padding="0">
            <Button
              onClick={async () => {
                setCurrentId((row[1] as string).substring(2));
                setLoading(true);
                await getWithdrawBankInfoThunk({
                  id: (row[1] as string).substring(2),
                });
                setLoading(false);
                setIsDetailsOpen(true);
              }}
              color={Color.orange}
              icon={Icon.search}
              disabled={role_yonetim_listesi['Çekim Bilgileri'] === '0'}
            />
          </Column>
        </Row>
      );
    }
    if (index === 12) {
      return (
        <Row width="100%" margin="0" padding="0">
          <Column width="35px" height="35px" margin="0" padding="0">
            <Button
              onClick={async () => {
                await copyWithdrawInfo(row);
              }}
              color={Color.gray}
              icon={Icon.copy}
            />
          </Column>
        </Row>
      );
    }
  };

  const fetchWithdrawList = () => {
    getWithdrawsBankThunk({
      islem_id,
      kullanici_id,
      kullanici_adi,
      tam_isim,
      hesap,
      method,
      cekim_durum,
      olusturma: olusturma ? olusturma.split(' - ').join(',') : '',
      guncelleme: guncelleme ? guncelleme.split(' - ').join(',') : '',
      page,
      pageCount: pagination,
      order,
      column,
      merchant: merchantCommaList,
    });
  };

  useEffect(() => {
    // console.log('we are here 1');
    fetchWithdrawList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, order, column, pagination]);

  const [itWorks, setItWorks] = useState(false);

  useEffect(() => {
      // reference must be same for addEventListener and removeEventListener
      // = can't use inline arrow function!
      function listenerWithdraw(event: { code: string; }) {
          if (event.code === 'Enter') {
            setItWorks(!itWorks);
            fetchWithdrawList();
          }
      }
      fetchWithdrawList();

      document.addEventListener('keypress', listenerWithdraw);
      return () => {
        document.removeEventListener('keypress', listenerWithdraw);
      }
  }, [!itWorks]);

  const DataTable = useDataTable<12>();

  return (
    <Row width="100%">
      <Modal
        onChange={(isOpen) => {
          setIsDetailsOpen(isOpen);
        }}
        open={isDetailsOpen}
        width="80vw"
      >
        <WithdrawDetail fetchWithdrawList={fetchWithdrawList} id={currentId} />
      </Modal>
      <Box
        boxTitle={
          <div style={{ textDecoration: 'underline' }}>
            <h3>
              <strong>Çekimler</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          <Row width="100%" padding="0">
            <Column width="65%">
              <Row>
                <Column width="20%">
                  <LabeledContainer label="İşlem ID">
                    <input
                      value={islem_id}
                      onChange={(event) => setProcessId(event.target.value)}
                    />
                  </LabeledContainer>
                </Column>
                <Column width="20%">
                  <LabeledContainer label="Kullanıcı ID">
                    <input
                      value={kullanici_id}
                      onChange={(event) => setUserId(event.target.value)}
                    />
                  </LabeledContainer>
                </Column>
                <Column width="20%">
                  <LabeledContainer label="Kullanıcı Adı">
                    <input
                      value={kullanici_adi}
                      onChange={(event) => setUserName(event.target.value)}
                    />
                  </LabeledContainer>
                </Column>
                <Column width="20%">
                  <LabeledContainer label="Method">
                    <select
                      value={method}
                      onChange={(event) => setMethod(event.target.value)}
                    >
                      <option value="">Hepsi</option>
                      <option value="Havale">Havale</option>
                      <option value="Papara">Papara</option>
                      <option value="Kripto">Kripto</option>
                    </select>
                  </LabeledContainer>
                </Column>
                <Column width="20%">
                  <LabeledContainer label="Durum">
                    <select
                      value={cekim_durum}
                      onChange={(event) => setStatus(event.target.value)}
                    >
                      <option value="">Hepsi</option>
                      <option value="Beklemede">Beklemede</option>
                      <option value="Onaylandi">Başarılı</option>
                      <option value="Reddedildi">Reddedilmiş</option>
                    </select>
                  </LabeledContainer>
                </Column>
              </Row>
              <Row>
                <Column width="25%">
                  <LabeledContainer label="Tam İsim">
                    <input
                      value={tam_isim}
                      onChange={(event) => setFullName(event.target.value)}
                    />
                  </LabeledContainer>
                </Column>
                <Column width="25%">
                  <LabeledContainer label="Hesap">
                    <input
                      value={hesap}
                      onChange={(event) => setAccount(event.target.value)}
                    />
                  </LabeledContainer>
                </Column>
                <Column width="25%">
                  <LabeledContainer label="Oluşturma">
                    <DatePicker
                      value={olusturma}
                      onChange={(startDate, endDate) => {
                        if (startDate && endDate)
                          setCreateDate(startDate + ' - ' + endDate);
                        else setCreateDate('');
                      }}
                    />
                  </LabeledContainer>
                </Column>
                <Column width="25%">
                  <LabeledContainer label="Güncelleme">
                    <DatePicker
                      value={guncelleme}
                      onChange={(startDate, endDate) => {
                        if (startDate && endDate)
                          setUpdateDate(startDate + ' - ' + endDate);
                        else setUpdateDate('');
                      }}
                    />
                  </LabeledContainer>
                </Column>
              </Row>
            </Column>
            <Column width="35%">
              <Row>
                <MultiSelect
                  value={selectedMerchants}
                  height="90px"
                  onChange={(selectedElements) => {
                    setSelectedMerchants(selectedElements);
                  }}
                  list={merchantSelectList}
                />
              </Row>
              <Row height="40px">
                <Button
                  onClick={() => {
                    setItWorks(!itWorks);
                    if (page === 1) fetchWithdrawList();
                    setCurrentPage(1);
                  }}
                  color={Color.orange}
                  icon={Icon.search}
                  loading={withdrawBankListLoading}
                >
                  Filtrele
                </Button>
              </Row>
            </Column>
          </Row>
          <Row width="100%">
            <DataTable
              onPageUpdate={(
                pageNumber,
                orderColumn,
                orderType,
                pagination
              ) => {
                setCurrentPage(pageNumber);
                setOrderColumn(orderColumn);
                setOrderType(orderType);
                setPagination(pagination);
              }}
              value={withdrawBankList}
              loading={withdrawBankListLoading}
              page={page}
              pagination={pagination}
              onColumnRender={onColumnRender}
              extraColumns={['Detay', 'Kopyala']}
              download={true}
              addRows={(rows) => {
                if (rows.length === 0) return [];
                const rowsSum = rows.reduce((acc, row, index, arr) => {
                  return row.map((e, i) => {
                    return (e as number) + (acc[i] as number);
                  });
                }, new Array(rows[0].length).fill(0));
                return [
                  [
                    '',
                    '',
                    '',
                    <b>
                      <i>{rowsSum[3].toFixed(2) + ' TL'}</i>
                    </b>,
                    <b>
                      <i>{rowsSum[4].toFixed(2) + ' TL'}</i>
                    </b>,
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                  ],
                ];
              }}
            />
          </Row>
        </Column>
      </Box>
    </Row>
  );
};

export default Withdraws;
