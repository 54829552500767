import React, { useEffect } from 'react';
import { useActions, useSelector } from '../../redux/hooks';
import { useAsyncTimeout } from '../../utils/utils';

const NewPaparaTransferCheck: React.FC = () => {
  const {
    global: { newPaparaTransferCreated, newPaparaTransferStatusChanged },
    auth: {
      currentUser: { role_yonetim_listesi },
      userLoaded,
    },
  } = useSelector((state) => state);

  const {
    setSoundPlaying,
    getNewPaparaTransfersThunk,
    setNewPaparaTransferCreated,
    setNewPaparaTransferStatusChanged,
  } = useActions();

  const [_, __, restartTimeout] = useAsyncTimeout(
    async () => {
      if (
        role_yonetim_listesi['Yeni Papara Yatırımları / Sonuçlandırma'] ===
          '1' &&
        userLoaded
      ) {
        await getNewPaparaTransfersThunk();
        restartTimeout();
      }
    },
    3000,
    [role_yonetim_listesi, userLoaded]
  );

  useEffect(() => {
    if (
      role_yonetim_listesi['Yeni Papara Yatırımları / Sonuçlandırma'] === '1' &&
      userLoaded
    ) {
      getNewPaparaTransfersThunk();
    }
  }, [role_yonetim_listesi, userLoaded]);

  useEffect(() => {
    if (
      (newPaparaTransferCreated || newPaparaTransferStatusChanged) &&
      role_yonetim_listesi['Yeni Papara Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1'
    ) {
      if (newPaparaTransferStatusChanged) {
        setSoundPlaying('update');
        document.title = 'Müşteri Yatırdım butonuna bastı...';
      } else if (newPaparaTransferCreated) {
        setSoundPlaying('bip');
        document.title = 'Yeni Yatırım isteği...';
      }
    }
  }, [
    newPaparaTransferCreated,
    newPaparaTransferStatusChanged,
    role_yonetim_listesi,
  ]);

  useAsyncTimeout(
    () => {
      if (newPaparaTransferCreated || newPaparaTransferStatusChanged) {
        setNewPaparaTransferCreated(false);
        setNewPaparaTransferStatusChanged(false);
      }
      document.title = 'Backoffice | Kralpay';
    },
    2000,
    [newPaparaTransferCreated, newPaparaTransferStatusChanged]
  );

  return <></>;
};

export default NewPaparaTransferCheck;
