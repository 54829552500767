import { OrderType } from '../../components/DataTable/types';
import { RoleInfoInitial, RoleObject } from '../Roles/types';

export interface UserFilterParams {
  kullanici_adi: string;
  page: number;
  pageCount: number;
  column?: string;
  order?: OrderType;
}

export interface UserInfoFields {
  is_active: string;
  kullanici_adi: string;
  role: string;
  email: string;
  isim: string;
  soyisim: string;
  passwordHash: string;
  new_password: string;
  iki_adimli_dogrulama: string;
  iki_adimli_dogrulama_key: string;
  merchants: string[];
  son_giris_saati: string;
  son_giris_tarihi: string;
  son_ip: string;
  son_agent: string;
  otp_enabled: boolean;
  otp_is_valid: boolean;
  is_foreign_finance: string;
  user_group: string;
}

export interface CurrentUserFields {
  id: number;
  is_active: string;
  kullanici_adi: string;
  role: string;
  email: string;
  isim: string;
  soyisim: string;
  son_giris: string;
  son_ip: string;
  otp_enabled: boolean;
  otp_is_valid: boolean;
  role_yonetim_listesi: RoleObject;
  user_group: string;
}

export const UserInfoInitial = {
  is_active: '',
  kullanici_adi: '',
  role: '',
  email: '',
  isim: '',
  soyisim: '',
  passwordHash: '',
  new_password: '',
  iki_adimli_dogrulama: '',
  iki_adimli_dogrulama_key: '',
  merchants: [],
  son_giris_saati: '',
  son_giris_tarihi: '',
  son_ip: '',
  son_agent: '',
  otp_enabled: false,
  otp_is_valid: false,
  is_foreign_finance: '',
  user_group: ''
};

export const CurrentUserInitial = {
  id: 0,
  is_active: '',
  kullanici_adi: '',
  role: '',
  email: '',
  isim: '',
  soyisim: '',
  son_giris: '',
  son_ip: '',
  otp_enabled: false,
  otp_is_valid: false,
  role_yonetim_listesi: RoleInfoInitial.role_yonetim_listesi,
  user_group: ''
};

export interface UserInfoParams {
  id: string | undefined;
}
