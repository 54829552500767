import React, { useEffect, useState } from 'react';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import LabeledContainer from '../../components/LabeledContainer/LabeledContainer';
import Button, { Color } from '../../components/Button/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useActions, useSelector } from '../../redux/hooks';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import { SelectEntry } from '../../components/MultiSelect/types';
import { isMissing, omitIfEmpty } from '../../utils/utils';
import Select from '../../components/Select/Select';
import { makeRequest } from '../../utils/request';
import TwoFactorAuth from "../../components/TwoFactorAuth/TwoFactorAuth";

const EditUser: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [is_active, setIsActive] = useState('');
  const [kullanici_adi, setUserName] = useState('');
  const [role, setRole] = useState<SelectEntry>();
  const [email, setEmail] = useState('');
  const [isim, setName] = useState('');
  const [soyisim, setSurname] = useState('');
  const [new_password, setPassword] = useState('');
  const [iki_adimli_dogrulama, setTwoStepVerification] = useState('');
  const [iki_adimli_dogrulama_key, setTwoStepVerificationKey] = useState('0');
  const [selectedMerchants, setSelectedMerchants] = useState<SelectEntry[]>([]);
  const [is_foreign_finance, setIsForeignFinance] = useState('');
  const [user_group, setUserGroup] = useState<SelectEntry>();

  const [openModal, setOpenModal] = useState(false);
  const [secret, setSecret] = useState({
      otpauth_url: "",
      base32: "",
  });

  const submitDisabled = isMissing([
    role?.id,
    email,
    isim,
    soyisim,
    kullanici_adi,
  ]);

  const {
    getUserInfoThunk,
    showMessage,
    setLoading,
    getWholeMerchantListThunk,
  } = useActions();

  const { userInfo, roleSelectList, userGroupSelectList, merchantWholeSelectList } = useSelector(
    (state) => state.global
  );

  const [otpEnabled, setOtpEnabled] = useState(userInfo.otp_enabled);

  const generateQrCode = async () => {
    try {
        const response = await makeRequest(
        {
            method: 'post',
            url: '/api/otp/generate',
            data: {
              user_id: id,
              email: userInfo.email
            },
        },
        showMessage
        );
    
        
        if (response.status === 200) {
          setOpenModal(true);
          setSecret({
              base32: response.data.base32,
              otpauth_url: response.data.otpauth_url,
          });
        } 
    } catch (error) {
        // Handle errors here, e.g., show an error message to the user
        console.error('Error generating OTP:', error);
    } finally {
        // store.setRequestLoading(false);
        console.log('what')
    }
    };
    
    
    const disableTwoFactorAuth = async () => {
    try {
        const response = await makeRequest(
        {
            method: 'post',
            url: '/api/otp/disable',
            data: {
              user_id: id
            },
        },
        showMessage
        );

        if (response.status === 200) {
          setOtpEnabled(false);
        } else {
          setOtpEnabled(true);
        }
    } catch (error) {
        // Handle errors here, e.g., show an error message to the user
        console.error('Error generating OTP:', error);
    } finally {
        // store.setRequestLoading(false);
    }
    
    };

  const getUserInfo = async () => {
    setLoading(true);
    await getUserInfoThunk({ id });
    await getWholeMerchantListThunk();
    setLoading(false);
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    setIsActive(userInfo.is_active);
    setUserName(userInfo.kullanici_adi);
    setEmail(userInfo.email);
    setName(userInfo.isim);
    setSurname(userInfo.soyisim);
    setTwoStepVerification(userInfo.iki_adimli_dogrulama);
    setTwoStepVerificationKey(userInfo.iki_adimli_dogrulama_key);
    setOtpEnabled(userInfo.otp_enabled);
    setIsForeignFinance(userInfo.is_foreign_finance);
  }, [userInfo]);

  useEffect(() => {
    setRole(roleSelectList.find(({ id }) => id == userInfo.role));
  }, [userInfo, roleSelectList]);


  useEffect(() => {
    setUserGroup(userGroupSelectList.find(({ id }) => id == userInfo.user_group));
  }, [userInfo, userGroupSelectList]);

  useEffect(() => {
    if (merchantWholeSelectList.length > 0 && userInfo.is_active) {
      setSelectedMerchants(
        merchantWholeSelectList.filter(
          ({ id }) => userInfo.merchants.findIndex((e) => e == id) !== -1
        )
      );
    }
  }, [userInfo, merchantWholeSelectList.length]);

  const editUser = async () => {
    const { rejected } = await makeRequest(
      {
        method: 'put',
        url: `/api/user/${id}`,
        data: omitIfEmpty({
          is_active,
          kullanici_adi,
          role: role?.id,
          email,
          isim,
          soyisim,
          new_password,
          iki_adimli_dogrulama,
          iki_adimli_dogrulama_key,
          is_foreign_finance,
          user_group: user_group?.id,
          merchants: selectedMerchants.map(({ id }) => id),
        }),
      },
      showMessage
    );
    if (!rejected) {
      navigate('/kullanicilar');
    }
  };

  useEffect(() => { //console.log('end of it'); 
  }, [otpEnabled]);


  const handleInformationUpdate = (data: boolean) => {
    setOtpEnabled(data);
  };

  return (
    
    <Row width="100%">
      <Box
        boxTitle={
          <div style={{ textDecoration: 'underline' }}>
            <h3>
              <strong>Kullanıcı Düzenleme</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          <Row width="100%">
            <Column width="25%">
              <Row>
                <Column width="100%">
                  <LabeledContainer label="Hesap">
                    <select
                      value={is_active}
                      onChange={(event) => setIsActive(event.target.value)}
                    >
                      <option value="true">Aktif</option>
                      <option value="false">Dondurulmuş</option>
                    </select>
                  </LabeledContainer>
                </Column>
              </Row>
              <Row>
                <Column width="100%">
                  <LabeledContainer label="Role">
                    <Select
                      value={role}
                      onChange={(entry) => setRole(entry)}
                      list={roleSelectList}
                    />
                  </LabeledContainer>
                </Column>
              </Row>
              <Row>
                <Column width="100%">
                  <LabeledContainer label="İsim">
                    <input
                      value={isim}
                      onChange={(event) => setName(event.target.value)}
                    />
                  </LabeledContainer>
                </Column>
              </Row>
              <Row>
                <Column width="100%">
                  <LabeledContainer label="İki adımlı doğrulama">
                    <input
                      value={userInfo.otp_enabled ? 'Açık' : 'Kapalı' }
                      disabled
                    />
                    
                  </LabeledContainer>
                </Column>
              </Row>
            </Column>
            <Column width="25%">
              <Row>
                <Column width="100%">
                  <LabeledContainer label="Kullanıcı Adı(Benzersiz)">
                    <input
                      value={kullanici_adi}
                      onChange={(event) => setUserName(event.target.value)}
                    />
                  </LabeledContainer>
                </Column>
              </Row>
              <Row>
                <Column width="100%">
                  <LabeledContainer label="Email(Benzersiz)">
                    <input
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </LabeledContainer>
                </Column>
              </Row>
              <Row>
                <Column width="100%">
                  <LabeledContainer label="Soy İsim">
                    <input
                      value={soyisim}
                      onChange={(event) => setSurname(event.target.value)}
                    />
                  </LabeledContainer>
                </Column>
              </Row>
              <Row>
                <Column width="100%">
                  <LabeledContainer label="Yeni Şifre(Optional)">
                    <input
                      value={new_password}
                      onChange={(event) => setPassword(event.target.value)}
                    />
                  </LabeledContainer>
                </Column>
              </Row>
              <Row>
                <Column width="100%">
                  <LabeledContainer label="İki adımlı doğrulama key" style={{ textAlign: 'center' }}>
                    {otpEnabled ? (
                      <button
                        type="button"
                        style = {{
                          outline: 'none',
                          color: 'white',
                          backgroundColor: 'red',
                          padding: '10px 20px',
                          border: 'none',
                          borderRadius: '0.5rem',
                          fontWeight: '500',
                          fontSize: '1rem',
                          marginBottom: '0.5rem',
                          cursor: 'pointer'
                        }}
                        onClick={() => disableTwoFactorAuth()}
                      >
                        2FA KAPAT
                      </button>
                    ) : (
                      <button
                        type="button"
                        style={{
                            outline: 'none',
                            color: 'white',
                            backgroundColor: '#8B5CF6',
                            padding: '10px 20px',
                            border: 'none',
                            maxWidth: '250px',
                            borderRadius: '0.5rem',
                            fontWeight: '500',
                            fontSize: '1rem',
                            marginBottom: '0.5rem',
                            cursor: 'pointer'
                        }}
                        onClick={() =>
                          generateQrCode()
                        }
                      >
                        2FA AKTIF ET
                      </button>
                    )}
                  </LabeledContainer>
                </Column>
              </Row>
            </Column>
            <Column width="50%">
              <Row>
                <Column width="100%">
                  <LabeledContainer label="İşlemler">
                    <Row>
                      <Column width="50%">
                        Son Giriş Saati:{' '}
                        <span style={{ fontWeight: '700' }}>
                          {userInfo.son_giris_saati}
                        </span>
                      </Column>
                      <Column width="50%">
                        Son Ip:{' '}
                        <span style={{ fontWeight: '700' }}>
                          {userInfo.son_ip}
                        </span>
                      </Column>
                    </Row>
                    <Row>
                      <Column width="50%">
                        Son Giriş Tarihi:{' '}
                        <span style={{ fontWeight: '700' }}>
                          {userInfo.son_giris_tarihi}
                        </span>
                      </Column>
                      <Column width="50%">
                        Son Agent:{' '}
                        <span style={{ fontWeight: '700' }}>
                          {userInfo.son_agent}
                        </span>
                      </Column>
                    </Row>
                  </LabeledContainer>
                </Column>
              </Row>
              <Row>
                <Column width="100%">
                    <LabeledContainer label="Dış Finans">
                      <select
                        value={is_foreign_finance}
                        onChange={(event) =>
                          setIsForeignFinance(event.target.value)
                        }
                      >
                        <option value="true">Açık</option>
                        <option value="false">Kapalı</option>
                    </select>
                    </LabeledContainer>
                </Column>
              </Row>
              <Row>
                <Column width="100%">
                    <LabeledContainer label="Kullanıcı Grup">
                      <Select
                        value={user_group}
                        onChange={(entry) => setUserGroup(entry)}
                        list={userGroupSelectList}
                      />
                    </LabeledContainer>
                </Column>
              </Row>
              <Row>
                <Column width="100%">
                  <LabeledContainer label="Yetkili Olduğu Merchantlar">
                    <MultiSelect
                      value={selectedMerchants}
                      height="90px"
                      onChange={(selectedElements) => {
                        setSelectedMerchants(selectedElements);
                      }}
                      list={merchantWholeSelectList}
                    />
                  </LabeledContainer>
                </Column>
              </Row>
            </Column>
          </Row>
          <Row width="100%" height="60px">
            <Button
              onClick={async () => {
                setLoading(true);
                await editUser();
                setLoading(false);
              }}
              color={Color.green}
              disabled={submitDisabled}
            >
              Düzenle
            </Button>
          </Row>
        </Column>
      </Box>
      {openModal && (
         <div
         style={{
           position: 'fixed',
           top: 0,
           left: 0,
           width: '100%',
           height: '100%',
           backgroundColor: 'rgba(0, 0, 0, 0.5)',
           zIndex: 1000,
           display: 'flex',
           justifyContent: 'center',
           alignItems: 'center',
         }}
       >
         <div
           style={{
             backgroundColor: '#fff',
             padding: '20px',
             borderRadius: '5px',
           }}
         >
          <TwoFactorAuth
              base32={secret.base32}
              otpauth_url={secret.otpauth_url}
              user_id={id as string}
              closeModal={() => setOpenModal(false)}

              onInformationUpdate={handleInformationUpdate}
            />
            </div>
          </div>
      )}
    </Row>
  );
};

export default EditUser;
